import React, { Fragment, useEffect, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import Filter from './filter';
import Playlist from './playlist';
import SharedPlaylist from './sharedPlaylist';
import Machine from './machine';

const Index = (props: any) => {
  useEffect(() => {
    const { pathname, hash } = window.location;
    if (hash === '#player' && pathname === '/') {
      const rootHeight = document.getElementById('root')?.offsetHeight || 0;
      window.scrollTo({
        top: rootHeight,
        behavior: 'smooth'
      });
    } 
  }, []);
  const [ currentTrack, updateCurrentTrack ] = useState(null);
  return (
    <Fragment>
      <Grid centered>
        <Grid.Row>
          <Grid.Column width="12">
            <div className="music-player-container" id="player">
              <Filter {...props}/>
              <Grid stackable centered>
                <Grid.Row>
                  <Grid.Column width={9}>
                    <Machine track={currentTrack} {...props}/>
                  </Grid.Column>
                  <Grid.Column width={7}>
                    {props.shared ?
                      <SharedPlaylist {...props} updateCurrentTrack={updateCurrentTrack}/> :
                      <Playlist {...props} updateCurrentTrack={updateCurrentTrack}/>
                    }
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Fragment>
  )
}

export default Index;