import React, { Component } from 'react';
import { connect } from "react-redux";
import Layout from '../components/layout/layout';
import { fetchMusic } from '../web/store/music/action';
import { fetchFilters } from '../web/store/filters/action';
import { fetchSync } from '../web/store/sync/action';

class Index extends Component<any, any> {
  componentDidMount = () => {
    const { fetchMusic, fetchFilters, fetchSync } = this.props;
    fetchMusic();
    fetchFilters();
    fetchSync();
  }

  render() {
    const { UIModule } = this.props;
    return (
      <Layout>
        <UIModule />
      </Layout>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    music: state.musicReducer.music,
    filters: state.filterReducer.filters,
    appliedFilters: state.filterReducer.appliedFilters,
    sync: state.syncReducer.sync
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchMusic: () => dispatch(fetchMusic()),
    fetchFilters: () => dispatch(fetchFilters()),
    fetchSync: () => dispatch(fetchSync()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);