import React, { useEffect } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import Routes from './routes/index';
import { PersistGate } from 'redux-persist/es/integration/react';
import configureStore from './store/index';
import { Provider } from 'react-redux';
import 'semantic-ui-css/semantic.min.css';

const { persistor, store } = configureStore();

const Root = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Router>
        <ScrollToTop />
        <Routes />
      </Router>
    </PersistGate>
  </Provider>
);

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default Root;