import React, { useState } from 'react';
import { Grid, Popup, Icon, Checkbox, Modal } from 'semantic-ui-react';

const Index = (props: any) => {
  return (
    <div className="filter-container">
      <div className="desktop-version">
        <DesktopFilter {...props} />
      </div>
      <div className="mobile-version">
        <MobileFilter {...props} />
      </div>
    </div>
  )
}

const modalStyle = {
  backgroundColor: '#2c2c2e',
  color: 'white'
}

const MobileFilter = (props: any) => {
  const [showModal, toggleModal] = useState(false);
  return (
    <Grid centered>
      <Grid.Row>
        <Grid.Column width="14">
          <div className="filter-component">
            <Icon name="bars" onClick={() => toggleModal(true)} />
            <span className="filter-component-title" onClick={() => toggleModal(true)}>Filters</span>
          </div>
          <Modal size="mini" open={showModal} closeIcon onClose={() => toggleModal(false)}>
            <Modal.Content style={modalStyle}>
              <FilterComponent {...props} />
            </Modal.Content>
          </Modal>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

const popupStyle = {
  border: 'none',
  borderRadius: 0,
  maxHeight: '200px',
  overflow: 'auto',
  backgroundColor: 'rgba(25, 25, 25, 0.9)'
}

const DesktopFilter = (props: any) => {
  return (
    <Grid centered>
      <Grid.Row>
        <Grid.Column width="14">
          <div className="filter-component">
            <FilterComponent {...props} />
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

const FilterComponent = (props: any) => {
  const { filters, updateFilters, appliedFilters } = props;
  return (
    <Grid stackable centered columns='equal'>
      <Grid.Row>
        {Object.keys(filters).map((key: string, index: number) => {
          const name = capitalise(key);
          const filterOptions = filters[key];
          const appliedFilterType = appliedFilters[key];
          const filterName = appliedFilterType.length > 0 ? `${name} (${appliedFilterType.length})` : name;
          return (
            <Grid.Column key={index}>
              <Popup style={popupStyle} trigger={<div className="text-center filter-name">{filterName}<Icon name="caret down" /></div>} on="click" position='bottom center' basic>
                <Popup.Content>
                  {filterOptions.map((option: any, index: number) => {
                    const { name, id } = option;
                    const filterApplied = appliedFilterType.some((entry: string) => entry === id);
                    return (
                      <div className="music-filter-option" key={id}>
                        <Checkbox label={name} onChange={() => updateFilters(key, id)} checked={filterApplied} />
                      </div>
                    )
                  })}
                </Popup.Content>
              </Popup>
            </Grid.Column>
          )
        })}
      </Grid.Row>
    </Grid>
  )
}

const capitalise = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);

export default Index;