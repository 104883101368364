import React, { Fragment, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import Track from './track';
import Disc from '../../../../../../assets/img/player/disc.png';
import Stick from '../../../../../../assets/img/player/vinylStick.png';

const Index = (props: any) => {
  const [ playing, updatePlayerState ] = useState(false);
  return (
    <Fragment>
      <Grid centered>
        <Grid.Row>
          <Grid.Column width="14">
            <div className="player-machine">
              <img alt="#" className={`vinylStick ${playing ? 'forwards' : 'backwords'}`} src={Stick} />
              <div className={`vinayl-disc ${playing ? 'playing' : ''}`}><img src={Disc} alt="#"/></div>
            </div>
            <Track {...props} playing={playing} updateState={updatePlayerState} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Fragment>
  )
}

export default Index;