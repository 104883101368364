import React from 'react';
import { Grid, Image } from 'semantic-ui-react';
import { useHistory } from "react-router-dom";
import Cinematic from '../../../assets/img/explore/types/Cinematic_Scores.svg';
import MiddleEastern from '../../../assets/img/explore/types/Middle_Eastern.svg';
import TVSeries from '../../../assets/img/explore/types/TV_Series.svg';
import Bollywood from '../../../assets/img/explore/types/Bollywood.svg';
import Precussion from '../../../assets/img/explore/types/Precussion.svg';
import Folk from '../../../assets/img/explore/types/Folk.svg';
import Tribal from '../../../assets/img/explore/types/Tribal.svg';
import Chants from '../../../assets/img/explore/types/Chants.svg';
import Trailer from '../../../assets/img/explore/types/Trailer.svg';

import Banner from '../../../assets/img/explore/banner.svg';

const Index = (props: any) => {
  const { updateFilters, filters } = props;
  const history = useHistory();
  const applyFilter = (key: string) => {
    const { types } = filters;
    const selectedFilter = (types.find((type: any) => type.name.toLowerCase() === key.toLowerCase()) || {}).id;
    const selectedFilters = [selectedFilter];
    updateFilters('types', selectedFilters);
    history.push('/#player');
  }
  return (
    <div className="explore-types-container" style={{backgroundImage: `url(${Banner})`}}>
      <div className="types-container">
        <Grid stackable columns={3}>
          <Grid.Row>
            <Grid.Column>
              <div className="type-item" onClick={() => applyFilter('Cinematic')}>
                <Image src={Cinematic} className="image"/>
                <span className="name">Cinematic Scores</span>
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className="type-item" onClick={() => applyFilter('Fusion')}>
                <Image src={MiddleEastern} className="image"/>
                <span className="name">Middle Eastern</span>
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className="type-item" onClick={() => applyFilter('Traditional')}>
                <Image src={TVSeries} className="image"/>
                <span className="name">TV Series</span>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <div className="type-item" onClick={() => applyFilter('Bollywood')}>
                <Image src={Bollywood} className="image"/>
                <span className="name">Bollywood</span>
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className="type-item" onClick={() => applyFilter('Percussive')}>
                <Image src={Precussion} className="image"/>
                <span className="name">Precussion</span>
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className="type-item" onClick={() => applyFilter('Folk')}>
                <Image src={Folk} className="image"/>
                <span className="name">Folk</span>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <div className="type-item" onClick={() => applyFilter('Tribal')}>
                <Image src={Tribal} className="image"/>
                <span className="name">Tribal</span>
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className="type-item" onClick={() => applyFilter('Chant')}>
                <Image src={Chants} className="image"/>
                <span className="name">Chants</span>
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className="type-item" onClick={() => applyFilter('Contemporary')}>
                <Image src={Trailer} className="image"/>
                <span className="name">Trailer</span>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  )
}

export default Index;