import React, { useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { connect } from "react-redux";
import List from './list';
import Sync from './sync';

const Index = (props: any) => {
  const { sync = [], music } = props;
  const initialSync = sync.length > 0 ? sync[0] : {};
  const [currnetSync, setSync] = useState(initialSync);

  return (
    <div className="sync-container">
      <div className="title-container">
        <div className="title unica-one-font text-center">Some of our sync placements we are really proud of</div>
        <div className="horizontal-rule"></div>
      </div>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column largeScreen={4} computer={5} tablet={6} mobile={16}>
            <List data={sync} setSync={setSync} />
          </Grid.Column>
          <Grid.Column largeScreen={12} computer={11} tablet={10} mobile={16}>
            <Sync sync={currnetSync} songs={music} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    music: state.musicReducer.music,
    filters: state.filterReducer.filters,
    appliedFilters: state.filterReducer.appliedFilters,
    sync: state.syncReducer.sync
  };
};

export default connect(mapStateToProps)(Index);