import { Firestore } from '../../../lib/firebase';
import { FILTER_STATUS_MAP } from './types';
import { defaultAppliedFilters } from './reducer';

export function fetchFilters(): any {
  return (dispatch: any) => {
    dispatch(fetchRegions());
    dispatch(fetchMoods());
    dispatch(fetchTempo());
    dispatch(fetchInstruments());
    dispatch(fetchTypes());
    dispatch(fetchVocals());
  }
}

export function fetchRegions(): any {
  return (dispatch: any) => {
    Firestore.collection("region").onSnapshot((snapshot) => {
      const { docs } = snapshot;
      const data = docs.map(doc => doc.data());
      dispatch({type: 'FETCH_REGION_SUCCESS', payload: data});
    });
  }
}

export function fetchMoods(): any {
  return (dispatch: any) => {
    Firestore.collection("mood").onSnapshot((snapshot) => {
      const { docs } = snapshot;
      const data = docs.map(doc => doc.data());
      dispatch({type: 'FETCH_MOOD_SUCCESS', payload: data});
    });
  }
}

export function fetchTempo(): any {
  return (dispatch: any) => {
    Firestore.collection("tempo").onSnapshot((snapshot) => {
      const { docs } = snapshot;
      const data = docs.map(doc => doc.data());
      dispatch({type: 'FETCH_TEMPO_SUCCESS', payload: data});
    });
  }
}

export function fetchInstruments(): any {
  return (dispatch: any) => {
    Firestore.collection("instruments").onSnapshot((snapshot) => {
      const { docs } = snapshot;
      const data = docs.map(doc => doc.data());
      dispatch({type: 'FETCH_INSTRUMENT_SUCCESS', payload: data});
    });
  }
}

export function fetchTypes(): any {
  return (dispatch: any) => {
    Firestore.collection("type").onSnapshot((snapshot) => {
      const { docs } = snapshot;
      const data = docs.map(doc => doc.data());
      dispatch({type: 'FETCH_TYPE_SUCCESS', payload: data});
    });
  }
}

export function fetchVocals(): any {
  return (dispatch: any) => {
    Firestore.collection("vocals").onSnapshot((snapshot) => {
      const { docs } = snapshot;
      const data = docs.map(doc => doc.data());
      dispatch({type: 'FETCH_VOCAL_SUCCESS', payload: data});
    });
  }
}

export function updateAppliedFilters(key: string, id: string): any {
  const type = FILTER_STATUS_MAP[key];
  return (dispatch: any) => {
    dispatch({type, payload: id});
  }
}

export function updateRegionFilter(id: string): any {
  return (dispatch: any) => {
    dispatch({type: 'UPDATE_SINGLE_REGION_SUCCESS', payload: id});
  }
}

export function applySingleFilterType(key: string, filters: Array<string>): any {
  const appliedFilters = Object.assign({}, defaultAppliedFilters);
  appliedFilters[key] = filters;
  return (dispatch: any) => {
    dispatch({type: 'UPDATE_SINGLE_FILTER_TYPE_SUCCESS', payload: appliedFilters});
  }
}