import { Firestore } from '../../../lib/firebase';

export function fetchSync(): any {
  return (dispatch: any) => {
    Firestore.collection("sync").onSnapshot((snapshot) => {
      const { docs } = snapshot;
      const data = docs.map(doc => doc.data());
      dispatch({type: 'FETCH_SYNC_SUCCESS', payload: data});
    });
  }
}