import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Progress, Button, Popup, Input } from 'semantic-ui-react';
import Song from '../../../../../../web/models/Song';

const Index = (props: any) => {
  const { track, music = [], playing, updateState } = props;
    const currentTrack = track && music.find((song: Song) => song.id === track);
    const { link = '', title = '' } = currentTrack || {}
    const audioEl = useRef<HTMLAudioElement>(null);
    const progressEl = useRef<HTMLDivElement>(null);
    const [ trackTime, updateTrackTime ] = useState(0);
    const [ currentTime, updateCurrentTime ] = useState(0);
    const onPlay = useCallback(() => {
      const { current } = audioEl;
      if (!current) {
        return;
      }
      current.play();
      console.log(current.duration) 
      updateTrackTime(current.duration);
      current.addEventListener('timeupdate', () => {
        updateCurrentTime(current.currentTime);
      });
      current.addEventListener('ended', () => {
        updateState(false);
        updateCurrentTime(0);
      })
      updateState(true);
    }, [updateState]);
    useEffect(() => {
      const { current } = audioEl;
      if (current) {
        updateState(false);
        if (progressEl.current) {
          progressEl.current.addEventListener('click', (event: any) => {
            const { offsetX, target } = event;
            const { offsetWidth } = target;
            const seekPercentage = Math.floor(offsetX * 100 / offsetWidth);
            const newTime = seekPercentage * current.duration / 100;
            current.currentTime = newTime;
            updateCurrentTime(newTime);
            updateTrackTime(current.duration);
          })
        }
        current.onloadedmetadata = () => {
          updateTrackTime(current.duration);
        };
        onPlay();
      }
    }, [audioEl, link, updateState, onPlay]);
    const onPause = () => {
      const { current } = audioEl;
      if (!current) {
        return;
      }
      current.pause();
      updateState(false);
    }
    const goBack = () => {
      const { current } = audioEl;
      if (!current) {
        return;
      }
      current.currentTime -= 5;
    }
    const goAhead = () => {
      const { current } = audioEl;
      if (!current) {
        return;
      }
      current.currentTime += 5;
    }
    const onvolumechange = (value: string) => {
      const { current } = audioEl;
      if (!current) {
        return;
      }
      current.volume = parseFloat(value);
    }
    const percentage = trackTime > 0 ? (currentTime * 100 / trackTime) : 0;

    return (
      <div className="audio-track-container">
        <div className="audio-track-title">
          {!link ? <span className="disabled-title">-- No Track Selected --</span> : <span>{title}</span>}
        </div>
        <div className="audio-track-time">
          {!link ? <span className="disabled-time">--/--</span> : <span>{covertToTime(currentTime)}/{covertToTime(trackTime)}</span>}
        </div>
        <div className="audio-track-progress-bar-container">
          <Progress percent={percentage} className="audio-track-progress-bar" indicating />
          <div className="audio-track-seeker" ref={progressEl}></div>
        </div>
        {link && <audio preload="metadata" src={link} className="audio-input" ref={audioEl} />}
        <div className="audio-controls-container">
          <div className="backword-button control-button">
            <Button className="elevated-button" icon="backward" onClick={goBack}/>
          </div>
          <div className="play-pause-button control-button">
            {playing ?
              <Button className="elevated-button" icon="pause" onClick={onPause} /> : 
              <Button className="elevated-button" icon="play" onClick={onPlay} />
            }
          </div>
          <div className="forward-button control-button">
            <Button className="elevated-button" icon="forward" onClick={goAhead}/>
          </div>
          <div className="sound-button control-button">
            <Popup style={popupStyle} on="click" trigger={<Button className="elevated-button" icon="volume up" />} position='bottom center' basic>
              <Popup.Content>
                <Input className="audio-controls-volume-slider" type="range" step="0.1" min={0} max={1} onChange={(e: any, data: any) => onvolumechange(data.value)}/>
              </Popup.Content>
            </Popup>
          </div>
        </div>
      </div>
    )
}

const popupStyle = {
  border: 'none',
  borderRadius: 0,
  backgroundColor: 'rgba(25, 25, 25, 0.9)'
}

const covertToTime = (time: number) => {
  if (isNaN(time)) {
    return `0:00`;
  }

  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  const secondsValue = seconds > 9 ? `${seconds}` : `0${seconds}`;
  return `${minutes}:${secondsValue}`;
}

export default Index;