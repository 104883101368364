import React, { Fragment, useState } from 'react';
import { connect } from "react-redux";
import { updateAppliedFilters } from '../../../../web/store/filters/action';
import { CREATE_PLAYLIST, FETCH_USER_PLAYLIST, ADD_TO_PLAYLIST, REMOVE_FROM_PLAYLIST } from '../../../../web/store/auth/actions';
import Search from './search';
import Player from './musicPlayer';

const Index = (props: any) => {
  const { music } = props;
  const [ selectedMusic, updateMusicSelection ] = useState(music);
  return (
    <Fragment>
      <Search {...props} updateSelection={updateMusicSelection} />
      <Player {...props} filteredMusic={selectedMusic} />
    </Fragment>
  )
}

const mapStateToProps = (state: any) => {
  return {
    music: state.musicReducer.music,
    filters: state.filterReducer.filters,
    appliedFilters: state.filterReducer.appliedFilters,
    sync: state.syncReducer.sync,
    user: state.authReducer.user,
    userPlaylist: state.authReducer.userPlaylist,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateFilters: (key: string, id: string) => dispatch(updateAppliedFilters(key, id)),
    createPlaylist: (user: any) => dispatch(CREATE_PLAYLIST(user)),
    fetchUserPlaylist: (id: string) => dispatch(FETCH_USER_PLAYLIST(id)),
    addToPlaylist: (listId: string, songId: string) => dispatch(ADD_TO_PLAYLIST(listId, songId)),
    removeFromPlaylist: (listId: string, songId: string) => dispatch(REMOVE_FROM_PLAYLIST(listId, songId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);