import React, { Fragment } from 'react';
import { connect } from "react-redux";
import { Grid, Image, Popup } from 'semantic-ui-react';
import Map from '../../../../assets/img/home/Map/WorldMap.svg';
import Song from '../../../../web/models/Song';
import { updateRegionFilter } from '../../../../web/store/filters/action';
import ScrollIntoView from 'react-scroll-into-view';

const popupStyle = {
  border: 'none',
  borderRadius: 0,
  maxHeight: '200px',
  overflow: 'auto',
  backgroundColor: 'rgba(25, 25, 25, 0.9)'
}

const Index = (props: any) => {
  const { filters, music, updateFilters } = props;
  const { regions = [] } = filters;
  return (
    <Fragment>
      <Grid stackable centered>
        <Grid.Row>
          <Grid.Column width={14}>
            <div className="world-map-container">
              <Image src={Map} fluid className="world-map"/>
              {regions.map((region: any) => {
                const { name, id, x, y } = region;
                const regionCircleStyle = {
                  left: `${x}%`,
                  top: `${y}%`,
                };
                const songCount = music.filter((song: Song) => song.region === id).length;
                return (
                  <ScrollIntoView selector="#player" key={id}>
                    <Popup key={id} style={popupStyle} position='bottom center' basic trigger={
                      <span style={regionCircleStyle} className="region-circle" onClick={() => updateFilters(id)}></span>}
                    >
                      <Popup.Content>
                        <div className="region-details-container" onClick={() => updateFilters(id)}>
                          <div className="region-name">{name}</div>
                          <div className="songs-count">number of songs : {songCount}</div>
                          <div className="track-description">
                            <i>Check tracks in {name}</i>
                          </div>
                        </div>
                      </Popup.Content>
                    </Popup>
                  </ScrollIntoView>
                )
              })}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Fragment>
  )
}

const mapStateToProps = (state: any) => {
  return {
    music: state.musicReducer.music,
    filters: state.filterReducer.filters,
    appliedFilters: state.filterReducer.appliedFilters,
    sync: state.syncReducer.sync
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateFilters: (id: string) => dispatch(updateRegionFilter(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);