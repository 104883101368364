import React, { Fragment } from 'react';
import MusicPlayer from './player';
import Map from './map';

const Index = (props: any) => {
  return (
    <Fragment>
      <Map />
      <MusicPlayer />
    </Fragment>
  )
}

export default Index;