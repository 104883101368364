import User from "../../models/User";

/**
 * Actions
 */

export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_UPDATE = 'AUTH_UPDATE'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const USER_PLAYLIST_UPDATE = 'USER_PLAYLIST_UPDATE'

export interface AuthState {
  user: User,
  userPlaylist: Array<string>,
}

export interface AuthLoginAction {
  type: typeof AUTH_LOGIN,
  payload: User
}

export interface AuthUpdateAction {
  type: typeof AUTH_UPDATE,
  payload: User
}

export interface AuthLogoutAction {
  type: typeof AUTH_LOGOUT,
  meta: {
    timestamp: number
  }
}

export type AuthTypeActions = AuthLoginAction | AuthLogoutAction | AuthUpdateAction;