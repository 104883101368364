import React, { Fragment } from 'react';
import { Input, Grid, Icon } from 'semantic-ui-react';
import Song from '../../../../web/models/Song';

const Index = (props: any) => {
  const onChange = (value: String) => {
    const { music, updateSelection } = props;
    const filteredMusic = music.filter((song: Song) => {
      const { title } = song;
      const searchString = (value || '').toLowerCase();
      return title.toLowerCase().includes(searchString);
    });
    updateSelection(filteredMusic);
  }
  return (
    <Fragment>
      <Grid stackable centered className="search_container">
        <Grid.Row>
          <Grid.Column width={12}>
            <Input onChange={(event: any, data: any) => onChange(data.value)} fluid className="search-input" placeholder="Search tracks" iconPosition='left'>
              <Icon name="search" size="large" color="grey"/>
              <input/>
            </Input>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Fragment>
  )
}

export default Index;