/**
 * Actions
 */

export const FETCH_SYNC_SUCCESS = 'FETCH_SYNC_SUCCESS';

export interface SyncState {
  sync: Array<object>
}

export interface SyncFetchAction {
  type: typeof FETCH_SYNC_SUCCESS,
  payload: Array<object>
}

export type SyncTypeActions = SyncFetchAction;