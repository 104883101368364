import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
// import "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyB3Vjr_ayoQvbtZFhX3AhqkL3uLiljYq28",
  authDomain: "world-music-c6565.firebaseapp.com",
  databaseURL: "https://world-music-c6565.firebaseio.com",
  projectId: "world-music-c6565",
  storageBucket: "world-music-c6565.appspot.com",
  messagingSenderId: "387014353256",
  appId: "1:387014353256:web:89539a25536dcfbf1676dd",
  measurementId: "G-0YQ4PS4VDT",
};

const Firebase = firebase.initializeApp(firebaseConfig);
export const Firestore = firebase.firestore();
// export const FirestoreDatabase = firebase.database();
export const auth = firebase.auth();
export const FirebaseStorage = firebase.storage();

export default Firebase;