import { AuthTypeActions, AUTH_LOGIN, AUTH_LOGOUT, AuthState, AUTH_UPDATE, USER_PLAYLIST_UPDATE } from './types';

const defaultUser = {
  id: '',
  name: '',
  profilePic: '',
  createdAt: new Date(),
  mobileNumber: '',
  email: '',
  password: '',
  username: '',
  token: ''
}
const initialState: AuthState = {
  user: defaultUser,
  userPlaylist: [],
}

export function authReducer(
  state = initialState,
  action: AuthTypeActions | any
): AuthState | any {
  switch (action.type) {
    case AUTH_LOGIN:
      return { ...state, user: action.payload }
    case AUTH_UPDATE:
      const updatedUser = Object.assign({}, state.user, action.payload);
      return { ...state, user: updatedUser }
    case AUTH_LOGOUT:
      return { ...state, user: defaultUser }
    case USER_PLAYLIST_UPDATE:
      return { ...state, userPlaylist: action.payload}
    default:
      return state
  }
}