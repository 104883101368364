import React from 'react';
import { Image } from 'semantic-ui-react';

const Index = (props: any) => {
  const { data = [], setSync } = props;
  return (
    <div className="sync-list">
      {data.map((item: any) => {
        const { image, album } = item;
        return (
          <div className="sync-item-container" key={album}>
            <Image src={image} className="sync-image" onClick={() => {setSync(item)}}/>
          </div>
        )
      })}
    </div>
  )
}

export default Index;