import { authReducer } from './auth/reducer';
import { musicReducer } from './music/reducer';
import { filterReducer } from './filters/reducer';
import { syncReducer } from './sync/reducer';
import { combineReducers } from 'redux'

export const rootReducer = combineReducers({
  authReducer,
  musicReducer,
  filterReducer,
  syncReducer
});

export type RootState = ReturnType<typeof rootReducer>