import React from 'react';
import { Image } from 'semantic-ui-react';
import PlayButton from '../../../../../assets/img/player/play-circle.svg';
import Song from '../../../../../web/models/Song';

const Index = (props: any) => {
  const { filteredMusic = [], appliedFilters, updateCurrentTrack } = props;
  const sortedMusic = filteredMusic.filter((song: Song) => {
    const { regions, moods, tempo, types, vocal, instruments } = appliedFilters;
    const isRegionPresent = regions.length > 0 ? regions.some((region: string) => song.region === region) : true;
    const isTempoPresent = tempo.length > 0 ? tempo.some((temp: string) => song.tempo === temp) : true;
    const isVocalPresent = vocal.length > 0 ? vocal.some((voc: string) => song.vocals === voc) : true;
    const isMoodPresent = moods.length > 0 ? moods.some((mood: string) => (song.mood || []).some((m: string) => m === mood)) : true;
    const isInstrumentPresent = instruments.length > 0 ? instruments.some((instrument: string) => (song.instruments || []).some((i: string) => i === instrument)) : true;
    const isTypePresent = types.length > 0 ? types.some((type: string) => (song.type || []).some((t: string) => t === type)) : true;
    return isRegionPresent && isTempoPresent && isVocalPresent && isMoodPresent && isInstrumentPresent && isTypePresent;
  });
  const songsCount = sortedMusic.length > 0 ? `(${sortedMusic.length})` : '';

  if (sortedMusic.length === 0) {
    return (
      <div className="playlist-container">
        <div className="playlist-title">Tracklist {songsCount}</div>
        <div className="empty-playlist">
          Nothing To Show
        </div>
      </div>
    )
  }

  return (
    <div className="playlist-container">
      <div className="playlist-title">
        <span>Tracklist {songsCount}</span>
      </div>
      <div className="playlist">
        {sortedMusic.map((track: any, index: number) => {
          return (
            <div key={index} className="song-item" onClick={() => {updateCurrentTrack(track.id)}}>
              <Image src={PlayButton} size="mini" />
              <div className="song-title">
                <span className="content">{track.title}</span>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Index;