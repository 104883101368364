import Filter from "../../models/Filter";
import AppliedFilter from "../../models/AppliedFilters";

/**
 * Actions
 */

export const FETCH_REGION_SUCCESS = 'FETCH_REGION_SUCCESS';
export const FETCH_TYPE_SUCCESS = 'FETCH_TYPE_SUCCESS';
export const FETCH_MOOD_SUCCESS = 'FETCH_MOOD_SUCCESS';
export const FETCH_TEMPO_SUCCESS = 'FETCH_TEMPO_SUCCESS';
export const FETCH_INSTRUMENT_SUCCESS = 'FETCH_INSTRUMENT_SUCCESS';
export const FETCH_VOCAL_SUCCESS = 'FETCH_VOCAL_SUCCESS';

export const UPDATE_REGION_FILTER_SUCCESS = 'UPDATE_REGION_FILTER_SUCCESS';
export const UPDATE_TYPE_FILTER_SUCCESS = 'UPDATE_TYPE_FILTER_SUCCESS';
export const UPDATE_MOOD_FILTER_SUCCESS = 'UPDATE_MOOD_FILTER_SUCCESS';
export const UPDATE_TEMPO_FILTER_SUCCESS = 'UPDATE_TEMPO_FILTER_SUCCESS';
export const UPDATE_INSTRUMENT_FILTER_SUCCESS = 'UPDATE_INSTRUMENT_FILTER_SUCCESS';
export const UPDATE_VOCAL_FILTER_SUCCESS = 'UPDATE_VOCAL_FILTER_SUCCESS';

export const UPDATE_SINGLE_REGION_SUCCESS = 'UPDATE_SINGLE_REGION_SUCCESS';
export const UPDATE_SINGLE_FILTER_TYPE_SUCCESS = 'UPDATE_SINGLE_FILTER_TYPE_SUCCESS';

export const FILTER_STATUS_MAP : any = {
  'regions': UPDATE_REGION_FILTER_SUCCESS,
  'types': UPDATE_TYPE_FILTER_SUCCESS,
  'moods': UPDATE_MOOD_FILTER_SUCCESS,
  'tempo': UPDATE_TEMPO_FILTER_SUCCESS,
  'instruments': UPDATE_INSTRUMENT_FILTER_SUCCESS,
  'vocal': UPDATE_VOCAL_FILTER_SUCCESS,
}

export interface FilterState {
  filters: Filter,
  appliedFilters: AppliedFilter,
}

// Fetch
export interface RegionFetchAction {
  type: typeof FETCH_REGION_SUCCESS,
  payload: Array<object>
}

export interface MoodFetchAction {
  type: typeof FETCH_REGION_SUCCESS,
  payload: Array<object>
}

export interface TempoFetchAction {
  type: typeof FETCH_TEMPO_SUCCESS,
  payload: Array<object>
}

export interface TypeFetchAction {
  type: typeof FETCH_REGION_SUCCESS,
  payload: Array<object>
}

export interface InstrumentFetchAction {
  type: typeof FETCH_REGION_SUCCESS,
  payload: Array<object>
}

export interface VocalFetchAction {
  type: typeof FETCH_TEMPO_SUCCESS,
  payload: Array<object>
}

// Update
export interface UpdateRegionFilterAction {
  type: typeof UPDATE_REGION_FILTER_SUCCESS,
  payload: Array<string>
}

export interface UpdateTypeFilterAction {
  type: typeof UPDATE_TYPE_FILTER_SUCCESS,
  payload: Array<string>
}

export interface UpdateMoodFilterAction {
  type: typeof UPDATE_MOOD_FILTER_SUCCESS,
  payload: Array<string>
}

export interface UpdateTempoFilterAction {
  type: typeof UPDATE_TEMPO_FILTER_SUCCESS,
  payload: Array<string>
}

export interface UpdateInstrumentFilterAction {
  type: typeof UPDATE_INSTRUMENT_FILTER_SUCCESS,
  payload: Array<string>
}

export interface UpdateVocalFilterAction {
  type: typeof UPDATE_VOCAL_FILTER_SUCCESS,
  payload: Array<string>
}

export interface UpdateSingleFilterTypeAction {
  type: typeof UPDATE_SINGLE_FILTER_TYPE_SUCCESS,
  payload: any
}

export type FilterTypeActions = 
  RegionFetchAction |
  MoodFetchAction |
  TempoFetchAction |
  TypeFetchAction |
  InstrumentFetchAction |
  VocalFetchAction |
  UpdateRegionFilterAction |
  UpdateTypeFilterAction |
  UpdateMoodFilterAction |
  UpdateTempoFilterAction |
  UpdateInstrumentFilterAction |
  UpdateVocalFilterAction;