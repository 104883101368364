import European from '../../../assets/img/explore/regions/europe.svg';
import SouthAmerican from '../../../assets/img/explore/regions/southAmerica.svg';
import NorthAmericanTribes from '../../../assets/img/explore/regions/northAmericanTribes.svg';
import Asian from '../../../assets/img/explore/regions/asian.svg';
import NorthAmerican from '../../../assets/img/explore/regions/northAmerica.svg';
import AsianTribes from '../../../assets/img/explore/regions/asianTribes.svg';
import MiddleEastern from '../../../assets/img/explore/regions/middleEastern.svg';
import SouthAmericanTribes from '../../../assets/img/explore/regions/southAmericanTribes.svg';
import African from '../../../assets/img/explore/regions/african.svg';
import Indian from '../../../assets/img/explore/regions/indianSubcontinent.svg';
import AfricanTribes from '../../../assets/img/explore/regions/africanTribes.svg';
import AustralianTribes from '../../../assets/img/explore/regions/australianTribes.svg';

export const Regions = {
  European: {
    title: 'European',
    image: European,
    regions: ['Europe'],
    countries: ['Russian', 'Czech Republic', 'Greece', 'Portugal', 'United Kingdom', 'Poland', 'Sweden', 'Hungary', 'Austria', 'Scotland', 'Denmark', 'Ireland', 'Albania']
  },
  SouthAmerican: {
    title: 'South American Tribes',
    image: SouthAmerican,
    regions: [],
    countries: ['Aztec', 'Zapotec', 'Mixtec']
  },
  NorthAmericanTribes: {
    title: 'North American Tribes',
    image: NorthAmericanTribes,
    regions: [],
    countries: ['Inuit', 'Salish', 'Navajo', 'Cherokee']
  },
  Asian: {
    title: 'Asian',
    image: Asian,
    regions: ['China', 'Afghanistan', 'Indonesia', 'Japan'],
    countries: ['Indonesia', 'Afghanistan', 'China', 'Tibet', 'Armenia', 'Japan', 'Philippines', 'Thailand', 'Korea', 'Mongolia', 'Myanmar']
  },
  NorthAmerican: {
    title: 'North American',
    image: NorthAmerican,
    regions: [],
    countries: ['USA', 'Canada', 'Mexico', 'Hondurus', 'Hawaii', 'West Indies']
  },
  AsianTribes: {
    title: 'Asian Tribes',
    image: AsianTribes,
    regions: [],
    countries: ['Moros and Christians', 'Papua', 'Asmat', 'Huli']
  },
  MiddleEastern: {
    title: 'Middle Eastern',
    image: MiddleEastern,
    regions: ['Turkey', 'Egypt', 'Saudi Arabia', 'Morocco'],
    countries: ['Turkey', 'Egypt', 'Saudi Arabia', 'Morocco', 'Iran', 'Iraq', 'Lebanon', 'Palestine', 'Syria', 'Israel', 'Qatar', 'Yemen', 'Oman', 'Azerbaijan']
  },
  SouthAmericanTribes: {
    title: 'South American Tribes',
    image: SouthAmericanTribes,
    regions: [],
    countries: ['Aztec', 'Zapotec', 'Mixtec']
  },
  African: {
    title: 'African',
    image: African,
    regions: ['Africa', 'Mauritius'],
    countries: ['African Folk', 'Ethiopia', 'Tanzania', 'Ghana', 'Mauritius', 'Nigeria', 'Zambia', 'Namibia']
  },
  Indian: {
    title: 'Indian Subcontinent',
    image: Indian,
    regions: ['India'],
    countries: ['India', 'Pakistan', 'Sri Lanka', 'Hindustani Classical', 'Carnatic', 'Bollywood Contemporary', 'Bollywood Dance', 'Punjabi', 'Nepal', 'Bhutan', 'Bangladesh']
  },
  AfricanTribes: {
    title: 'African Tribes',
    image: AfricanTribes,
    regions: [],
    countries: ['Maasai', 'Himba', 'Zulu', 'Bushman', 'Ndebele', 'Samburu', 'Hadzabe', 'Hamer', 'Dogon']
  },
  AustralianTribes: {
    title: 'Australian Tribes',
    image: AustralianTribes,
    regions: ['Australia'],
    countries: ['Aboriginal', 'Torres Strait Islander']
  }
}