import {
  FilterTypeActions,
  FETCH_REGION_SUCCESS,
  FETCH_MOOD_SUCCESS,
  FETCH_TEMPO_SUCCESS,
  FETCH_INSTRUMENT_SUCCESS,
  FETCH_TYPE_SUCCESS,
  FETCH_VOCAL_SUCCESS,
  UPDATE_REGION_FILTER_SUCCESS,
  UPDATE_TYPE_FILTER_SUCCESS,
  UPDATE_MOOD_FILTER_SUCCESS,
  UPDATE_TEMPO_FILTER_SUCCESS,
  UPDATE_INSTRUMENT_FILTER_SUCCESS,
  UPDATE_VOCAL_FILTER_SUCCESS,
  UPDATE_SINGLE_REGION_SUCCESS,
  UPDATE_SINGLE_FILTER_TYPE_SUCCESS,
  FilterState
} from './types';

const defaultFilters = {
  regions: [],
  moods: [],
  tempo: [],
  instruments: [],
  types: [],
  vocal: []
}

export const defaultAppliedFilters: any = {
  regions: [],
  moods: [],
  tempo: [],
  instruments: [],
  types: [],
  vocal: []
}

const initialState: FilterState = {
  filters: defaultFilters,
  appliedFilters: defaultAppliedFilters
}

export function filterReducer(
  state = initialState,
  action: FilterTypeActions | any
): FilterState | any {
  switch (action.type) {
    case FETCH_REGION_SUCCESS:
      return { ...state, filters: Object.assign({}, state.filters, { regions: action.payload})}
    case FETCH_MOOD_SUCCESS:
      return { ...state, filters: Object.assign({}, state.filters, { moods: action.payload})}
    case FETCH_TEMPO_SUCCESS:
      return { ...state, filters: Object.assign({}, state.filters, { tempo: action.payload})}
    case FETCH_INSTRUMENT_SUCCESS:
      return { ...state, filters: Object.assign({}, state.filters, { instruments: action.payload})}
    case FETCH_TYPE_SUCCESS:
      return { ...state, filters: Object.assign({}, state.filters, { types: action.payload})}
    case FETCH_VOCAL_SUCCESS:
      return { ...state, filters: Object.assign({}, state.filters, { vocal: action.payload})}

    case UPDATE_REGION_FILTER_SUCCESS:
      const newRegions = updateFilter(state.appliedFilters.regions, action.payload);
      return { ...state, appliedFilters: Object.assign({}, state.appliedFilters, { regions: newRegions})}
    case UPDATE_TYPE_FILTER_SUCCESS:
      const newTypes = updateFilter(state.appliedFilters.types, action.payload);
      return { ...state, appliedFilters: Object.assign({}, state.appliedFilters, { types: newTypes})}
    case UPDATE_MOOD_FILTER_SUCCESS:
      const newMoods = updateFilter(state.appliedFilters.moods, action.payload);
      return { ...state, appliedFilters: Object.assign({}, state.appliedFilters, { moods: newMoods})}
    case UPDATE_TEMPO_FILTER_SUCCESS:
      const newTempos = updateFilter(state.appliedFilters.tempo, action.payload);
      return { ...state, appliedFilters: Object.assign({}, state.appliedFilters, { tempo: newTempos})}
    case UPDATE_INSTRUMENT_FILTER_SUCCESS:
      const newInstruments = updateFilter(state.appliedFilters.instruments, action.payload);
      return { ...state, appliedFilters: Object.assign({}, state.appliedFilters, { instruments: newInstruments})}
    case UPDATE_VOCAL_FILTER_SUCCESS:
      const newVocals = updateFilter(state.appliedFilters.vocal, action.payload);
      return { ...state, appliedFilters: Object.assign({}, state.appliedFilters, { vocal: newVocals})}

    case UPDATE_SINGLE_REGION_SUCCESS:
      return { ...state, appliedFilters: Object.assign({}, state.appliedFilters, { regions: [action.payload]})}
    case UPDATE_SINGLE_FILTER_TYPE_SUCCESS:
      return { ...state, appliedFilters: Object.assign({}, action.payload)}
    default:
      return state
  }
}

const updateFilter = (currentFilters: Array<string>, newEntry: string) => {
  const updatedFilterDomain = currentFilters.slice();
  const filterExistsIndex = updatedFilterDomain.findIndex((current: string) => current === newEntry);
  if (filterExistsIndex !==  -1) {
    updatedFilterDomain.splice(filterExistsIndex, 1);
  } else {
    updatedFilterDomain.push(newEntry);
  }
  return updatedFilterDomain;
}