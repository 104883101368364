import React, { Component, Fragment } from 'react';
import Footer from './footer/footer';
import Login from './../../modules/auth/UI/login';
import { Menu, Image, Sidebar, Icon } from 'semantic-ui-react';
import Logo from '../../assets/img/logo/logo.svg';
import Name from '../../assets/img/logo/logo-name.png';
import { Link, useLocation } from 'react-router-dom';

class Layout extends Component<any, any> {
  render() {
    const { children } = this.props;
    return (
      <Fragment>
        <DesktopLayout children={children} />
        <MobileLayout children={children} />
      </Fragment>
    )
  }
}

const DesktopLayout = (props: any) => {
  const { children } = props;
  const location = useLocation();
  const { pathname } = location;
  return (
    <div className="desktop-layout layout-container">
      <div className="desktop-header">
        <Menu fixed='top' borderless className="fixed-menu">
          <Link to="/" className="menu-link">
            <Image src={Logo} className="logo"/>
          </Link>
          <Link to="/" className="menu-link">
            <Image src={Name} className="logo-name"/>
          </Link>
          <Menu.Menu position="right">
            <Menu.Item as={Link} to={pathname === '/' ? '/explore': '/'} className="menu-item abel-font">{pathname === '/' ? 'Explore': 'Home'}</Menu.Item>
            <Menu.Item as={Link} to={pathname === '/subscribe' ? '/explore': '/subscribe'} className="menu-item abel-font">{pathname === '/subscribe' ? 'Explore': 'Subscribe'}</Menu.Item>
            <Menu.Item as={Link} to={pathname === '/sync' ? '/explore': '/sync'} className="menu-item abel-font">{pathname === '/sync' ? 'Explore': 'Sync'}</Menu.Item>
            <Login />
          </Menu.Menu>
        </Menu>
        <Menu className="placeholder-menu" borderless>
          <Link to="/" className="menu-link">
            <Image src={Logo} className="logo"/>
          </Link>
          <Link to="/" className="menu-link">
            <Image src={Name} className="logo-name"/>
          </Link>
          <Menu.Menu position="right">
            <Menu.Item as={Link} to="/" className="menu-item abel-font">Explore</Menu.Item>
            <Menu.Item as={Link} to="/" className="menu-item abel-font">Subscribe</Menu.Item>
            <Menu.Item as={Link} to="/" className="menu-item abel-font">Sync</Menu.Item>
            <Menu.Item className="menu-item abel-font">Login</Menu.Item>
          </Menu.Menu>
        </Menu>
      </div>
      <div style={{flex: 1}}>
        {children}
      </div>
      <Footer/>
    </div>
  )
}

const MobileLayout = (props: any) => {
  const [visible, setVisible] = React.useState(false);
  const { children } = props;
  return (
    <div className="mobile-layout layout-container">
      <Sidebar.Pushable as={Menu}>
        <Sidebar
          as={Menu}
          animation='overlay'
          icon='labeled'
          inverted
          onHide={() => setVisible(false)}
          vertical
          visible={visible}
          width='thin'
        >
          <Menu.Item as={Link} to="/" className="menu-item abel-font">Home</Menu.Item>
          <Menu.Item as={Link} to="/explore" className="menu-item abel-font">Explore</Menu.Item>
          <Menu.Item as={Link} to="/subscribe" className="menu-item abel-font">Subscribe</Menu.Item>
          <Menu.Item as={Link} to="/sync" className="menu-item abel-font">Sync</Menu.Item>
          <Login type="mobile" />
        </Sidebar>

        <Sidebar.Pusher dimmed={visible}>
          <div className="mobile-header">
            <Menu fixed='top' borderless className="fixed-menu">
              <Menu.Item onClick={() => setVisible(true)}>
                <Icon name='sidebar' style={{color: 'white'}}/>
              </Menu.Item>
              <Link to="/" className="menu-link">
                <Image src={Name} className="logo-name"/>
              </Link>
            </Menu>
            <Menu className="placeholder-menu">
              <Menu.Item onClick={() => setVisible(true)}>
                <Icon name='sidebar' style={{color: 'white'}}/>
              </Menu.Item>
            </Menu>
          </div>
          <div>
            {children}
          </div>
          <Footer/>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  )
}

export default Layout;