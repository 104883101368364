import React, { useCallback, useState } from 'react';
import { Modal, Menu, Input, Button, Dropdown, Icon } from 'semantic-ui-react';
import { connect } from "react-redux";
import { VALIDATE_EMAIL, SEND_OTP, EMAIL_OTP_GENERATOR } from '../../../lib/emailHelper';
import { SEND_OTP_TO_DB, VERIFY_OTP, login, logout } from '../../../web/store/auth/actions';

const OTP_STATUS = {
  NOT_SENT: 'not_sent',
  SENDING: 'sending',
  SEND_SUCCESS: 'send_success',
  SEND_ERROR: 'send_error',
  VERIFYING: 'verifying',
  VERIFY_SUCCESS: 'verify_success',
  VERIFY_ERROR: 'verify_error',
};

const disableEmailInput = (status: string) => [OTP_STATUS.SEND_SUCCESS, OTP_STATUS.VERIFYING, OTP_STATUS.VERIFY_ERROR].some((otpStatus: string) => otpStatus === status);

const Index = (props: any) => {
  const [open, toggle] = useState(false);
  const [ email, updateEmail ] = useState('');
  const [ otpStatus, updateOTPStatus ] = useState({ status: OTP_STATUS.NOT_SENT, message: '' });
  const closeModal = () => {
    updateEmail('');
    updateOTPStatus({ status: OTP_STATUS.NOT_SENT, message: '' });
    toggle(false);
  }
  const onChange = (event: any, data: any) => {
    updateEmail(data.value);
    updateOTPStatus({ status: OTP_STATUS.NOT_SENT, message: '' });
  }
  const sendOTP = useCallback(async() => {
    const OTP = EMAIL_OTP_GENERATOR();
    updateOTPStatus({ status: OTP_STATUS.SENDING, message: '' });
    const response = await SEND_OTP(email, OTP);
    const { isError, message } = response;
    if (isError) {
      updateOTPStatus({ status: OTP_STATUS.SEND_ERROR, message });
    } else {
      const saveRes = await SEND_OTP_TO_DB(email, OTP);
      if (saveRes.isError) {
        updateOTPStatus({ status: OTP_STATUS.SEND_ERROR, message: saveRes.message});
      } else {
        updateOTPStatus({ status: OTP_STATUS.SEND_SUCCESS, message: ''});
      }
    }
  }, [email]);
  const isEmailValid = !email || VALIDATE_EMAIL(email);
  const shouldSendOTP = email && isEmailValid && !(otpStatus.status === OTP_STATUS.SENDING);
  const [ otp, updateOTP ] = useState('');
  const onOTPChange = (event: any, data: any) => {
    updateOTP(data.value);
  };
  const disableSendOTP = otp.length !== 6;
  const verifyOTP = useCallback(async() => {
    updateOTPStatus({ status: OTP_STATUS.VERIFYING, message: '' });
    const verifyResponse = await VERIFY_OTP(email, otp);
    if (verifyResponse.isError) {
      updateOTPStatus({ status: OTP_STATUS.VERIFY_ERROR, message: verifyResponse.message});
    } else {
      updateOTPStatus({ status: OTP_STATUS.VERIFY_SUCCESS, message: ''});
      const user = verifyResponse.data;
      const { login } = props;
      login(user);
    }
  }, [email, otp, props]);

  const { user, logout, type } = props;
  if (user && user.id) {
    if (type === 'mobile') {
      return (
        <>
          <Menu.Item className="menu-item abel-font">Profile</Menu.Item>
          <Menu.Item className="menu-item abel-font" onClick={logout}>Logout</Menu.Item>
        </>
      )
    }
  
    return (
      <Dropdown item icon={<Icon name="user circle" className="profile-menu" />}>
        <Dropdown.Menu className="profile-menu-dropdown">
          <Dropdown.Item>{user.email}</Dropdown.Item>
          <Dropdown.Item className="text-center" onClick={logout}>Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  return (
    <>
      <Menu.Item as='a' className="menu-item abel-font" onClick={() => toggle(true)}>Login</Menu.Item>
      <Modal dimmer='blurring' open={open} closeIcon size="mini" onClose={closeModal}>
        <Modal.Content className="login-container">
          <div className="login-title unica-one-font">Login to download your tracks</div>
          <Input placeholder="Your Email" fluid className="login-email" onChange={onChange} error={!isEmailValid} disabled={disableEmailInput(otpStatus.status)} />
          {!isEmailValid && <div className="email-input-error">Email not valid</div>}
          {otpStatus.status === OTP_STATUS.SEND_ERROR && <div className="email-input-error">{otpStatus.message}</div>}
          {otpStatus.status === OTP_STATUS.SEND_SUCCESS && <div className="email-input-success">OTP Sent Successfully</div>}
          {([OTP_STATUS.SEND_SUCCESS, OTP_STATUS.VERIFYING, OTP_STATUS.VERIFY_ERROR, OTP_STATUS.VERIFY_SUCCESS].some((status: string) => status === otpStatus.status)) ?
            <div className="send-otp-container">
              <Input placeholder="Enter OTP" fluid className="login-email" onChange={onOTPChange} />
              {otpStatus.status === OTP_STATUS.VERIFY_ERROR && <div className="email-input-error">{otpStatus.message}</div>}
              {otpStatus.status === OTP_STATUS.VERIFY_SUCCESS && <div className="email-input-success">OTP verified successfully</div>}
              <Button className="send-otp verify-otp" disabled={disableSendOTP} onClick={verifyOTP} loading={otpStatus.status === OTP_STATUS.VERIFYING}>Verify OTP</Button>
            </div> :
            <div className="send-otp-container">
              <Button className="send-otp" disabled={!shouldSendOTP} onClick={sendOTP} loading={otpStatus.status === OTP_STATUS.SENDING}>Send OTP</Button>
            </div>
          }
        </Modal.Content>
      </Modal>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    user: state.authReducer.user,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    login: (user: any) => dispatch(login(user)),
    logout: () => dispatch(logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);