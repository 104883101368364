import { SyncTypeActions, FETCH_SYNC_SUCCESS, SyncState } from './types';

const defaultSync: never[] = []
const initialState: SyncState = {
  sync: defaultSync
}

export function syncReducer(
  state = initialState,
  action: SyncTypeActions | any
): SyncState | any {
  switch (action.type) {
    case FETCH_SYNC_SUCCESS:
      return { ...state, sync: action.payload }
    default:
      return state
  }
}