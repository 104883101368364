import React, { useState } from 'react';
import { Grid, Image } from 'semantic-ui-react';
import PlayButton from '../../../assets/img/player/play-circle.svg';
import AwardLeft from '../../../assets/img/sync/awardLeft.svg';
import AwardRight from '../../../assets/img/sync/awardRight.svg';
import Player from '../../home/UI/player/musicPlayer/machine/track';

const Index = (props: any) => {
  const { sync = {}, songs } = props;
  const { image, album, logo, type, year, country, network, season, episode, song, achievements } = sync;
  const playList = song.map((id: string) => songs.find((item: any) => item.id === id) || {});
  const [currentTrack, updateCurrentTrack] = useState('');
  const [playing, updatePlayerStatus] = useState(false);
  return (
    <div className="sync-album-container">
      <Grid stackable centered columns={2}>
        <Grid.Row>
          <Grid.Column largeScreen={8} computer={6} tablet={16} mobile={16}>
            <Image src={image} className="album-image"/>
          </Grid.Column>
          <Grid.Column largeScreen={8} computer={10} tablet={16} mobile={16}>
            <div className="title unica-one-font">
              {album}
            </div>
            <div className="horizontal-rule"></div>
            <div className="content">
              <div>Type: {type}</div>
              <div>Release: {year}</div>
              <div>Country: {country}</div>
              <div>Network: {network}</div>
              <div>Season: {season}</div>
              <div>Episode: {episode}</div>
              <div>
                <Image src={logo} size="small" />
              </div>
            </div>
            <div className="title unica-one-font">
              Songs
            </div>
            <div className="sync-player-container">
              <Player music={playList} track={currentTrack} playing={playing} updateState={updatePlayerStatus}/>
            </div>
            <div className="songs-container">
              {playList.map((song: any) => {
                return (
                  <div key={song.id} className="song-item-container" onClick={() => {
                    updateCurrentTrack(song.id);
                    updatePlayerStatus(true);
                  }}>
                    <div className="playbutton">
                      <Image src={PlayButton} />
                    </div>
                    <div className="song-title">
                      {song.title}
                    </div>
                  </div>
                )
              })}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {achievements && achievements.length > 0 && <Achievements data={achievements}/>}
    </div>
  )
}

const Achievements = (props: any) => {
  const { data } = props;
  return (
    <div className="achievements-container">
      <div className="horizontal-rule"></div>
      <div className="title unica-one-font">
        Film Festivals
      </div>
      <div className="awards-data">
        {data.map((award: string, index: number) => {
          return (
            <div key={index} className="award-conatiner">
              <Image src={AwardLeft} />
              <div className="content">
                <div>Official Selection at</div>
                <div className="award-title text-center">{award}</div>
              </div>
              <Image src={AwardRight} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Index;