import React, { Component } from 'react';
import { Grid, Icon } from 'semantic-ui-react';

class Footer extends Component<any> {
  render() {
    return (
      <div className="footer-container">
        <div className="desktop-footer">
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={8}>
                <div>© Copyrights 2019-2020 all rights reserved</div>
              </Grid.Column>
              <Grid.Column width={8}>
                <Grid stackable columns={3} divided>
                  <Grid.Row>
                    <Grid.Column>
                      <div className="contact-us-container">
                        <div className="title">Contact Us</div>
                        <div>
                          <Icon name="twitter"/>
                          <Icon name="facebook f"/>
                          <Icon name="instagram" />
                        </div>
                      </div>
                    </Grid.Column>
                    <Grid.Column>
                      <div className="text-center">Privacy Policy</div>
                    </Grid.Column>
                    <Grid.Column>
                      <div className="text-center">Terms and Conditions</div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <div className="mobile-footer">
          <div className="text-center">© Copyrights 2019-2020 all rights reserved</div>
          <div className="mobile-data-container">
            <div className="mobile-item" style={{flex: 1}}>Contact Us</div>
            <div className="mobile-item" style={{flex: 1}}>
              <Icon name="twitter"/>
              <Icon name="facebook f"/>
              <Icon name="instagram" />
            </div>
          </div>
          <div className="mobile-data-container">
            <div className="mobile-item">Privacy Policy</div>
            <div className="mobile-item">Terms and Conditions</div>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer;