import React from 'react';
import { Grid, Image } from 'semantic-ui-react';
import { Regions } from '../data/index';
import { useHistory } from "react-router-dom";

const Index = (props: any) => {
  return (
    <div className="explore-regions-container">
      <Grid stackable columns={4}>
        <Grid.Row>
          <Grid.Column>
            <div className="region-column">
              <div className="regions-container">
                <Region data={Regions.European} {...props} />
                <Region data={Regions.SouthAmerican} {...props} />
                <Region data={Regions.NorthAmericanTribes} {...props} />
              </div>
              <div className="divider"></div>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className="region-column">
              <div className="regions-container">
                <Region data={Regions.Asian} {...props} />
                <Region data={Regions.NorthAmerican} {...props} />
                <Region data={Regions.AsianTribes} {...props} />
              </div>
              <div className="divider"></div>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className="region-column">
              <div className="regions-container">
                <Region data={Regions.MiddleEastern} {...props} />
                <Region data={Regions.SouthAmericanTribes} {...props} />
                <Region data={Regions.African} {...props} />
              </div>
              <div className="divider"></div>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div>
              <Region data={Regions.Indian} {...props} />
              <Region data={Regions.AfricanTribes} {...props} />
              <Region data={Regions.AustralianTribes} {...props} />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

const Region = (props: any) => {
  const { image, title, countries, regions } = props.data || {};
  const { updateFilters, filters } = props;
  const history = useHistory();
  const applyFilter = () => {
    const selectedFilters = regions.map((name: string) => (filters.regions.find((region: any) => region.name === name) || {}).id);
    updateFilters('regions', selectedFilters);
    history.push('/#player');
  }

  return (
    <div className="region-container">
      <div className="item-image-container" onClick={applyFilter}>
        <Image src={image} className="item-image"/>
      </div>
      <div className="title unica-one-font">
        {title}
      </div>
      <hr className="horizontal-rule"/>
      <div className="country-container">
        {countries.map((country: string, index: number) => <div className="country" key={index}>{country}</div>)}
      </div>
    </div>
  )
}

export default Index;