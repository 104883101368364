import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Container from '../../container/index';
import Home from '../../modules/home/UI/index';
import Explore from '../../modules/explore/UI/index';
import Sync from '../../modules/sync/UI/index';
import Subscribe from '../../modules/subscribe/UI/index';
import Playlist from '../../modules/playlist/UI/index';
import SnackbarProvider from 'react-simple-snackbar'

const Index = () => (
  <SnackbarProvider>
    <Switch>
      <Route exact path="/" render={props => (<Container UIModule={Home} {...props}/>)}/>
      <Route exact path="/explore" render={props => (<Container UIModule={Explore} {...props}/>)}/>
      <Route exact path="/sync" render={props => (<Container UIModule={Sync} {...props}/>)}/>
      <Route exact path="/subscribe" render={props => (<Container UIModule={Subscribe} {...props}/>)}/>
      <Route exact path="/playlist/:id" render={props => (<Container UIModule={Playlist} {...props}/>)}/>
    </Switch>
  </SnackbarProvider>
)

export default Index;