import React, { useEffect } from 'react';
import { Icon, Image, Label } from 'semantic-ui-react';
import PlayButton from '../../../../../assets/img/player/play-circle.svg';
import Song from '../../../../../web/models/Song';

const Index = (props: any) => {
  const { filteredMusic = [], appliedFilters, updateCurrentTrack, user, createPlaylist, fetchUserPlaylist, userPlaylist, addToPlaylist, removeFromPlaylist } = props;
  const sortedMusic = filteredMusic.filter((song: Song) => {
    const { regions, moods, tempo, types, vocal, instruments } = appliedFilters;
    const isRegionPresent = regions.length > 0 ? regions.some((region: string) => song.region === region) : true;
    const isTempoPresent = tempo.length > 0 ? tempo.some((temp: string) => song.tempo === temp) : true;
    const isVocalPresent = vocal.length > 0 ? vocal.some((voc: string) => song.vocals === voc) : true;
    const isMoodPresent = moods.length > 0 ? moods.some((mood: string) => (song.mood || []).some((m: string) => m === mood)) : true;
    const isInstrumentPresent = instruments.length > 0 ? instruments.some((instrument: string) => (song.instruments || []).some((i: string) => i === instrument)) : true;
    const isTypePresent = types.length > 0 ? types.some((type: string) => (song.type || []).some((t: string) => t === type)) : true;
    return isRegionPresent && isTempoPresent && isVocalPresent && isMoodPresent && isInstrumentPresent && isTypePresent;
  });
  const songsCount = sortedMusic.length > 0 ? `(${sortedMusic.length})` : '';
  const defaultPlaylistId = user && user.playlists && user.playlists[0];
  useEffect(() => {
    if (defaultPlaylistId) {
      fetchUserPlaylist(defaultPlaylistId);
    }
  }, [defaultPlaylistId, fetchUserPlaylist]);
  
  if (sortedMusic.length === 0) {
    return (
      <div className="playlist-container">
        <div className="playlist-title">Tracklist {songsCount}</div>
        <div className="empty-playlist">
          Nothing To Show
        </div>
      </div>
    )
  }

  return (
    <div className="playlist-container">
      <div className="playlist-title">
        <span>Tracklist {songsCount}</span>
        {user && user.id && (defaultPlaylistId ?
          <div className="user-playlist-container">
            <Label className="user-playlist-label" onClick={() => createPlaylist(user)}>
              <a className="content" href={`/playlist/${defaultPlaylistId}`}>My Playlist</a>
            </Label>
            <Label className="user-playlist-label" onClick={() => createPlaylist(user)}>
              <Icon name="copy" className="action-icon" />
            </Label>
          </div> :
          <div className="user-playlist-container">
            <Label className="user-playlist-label" onClick={() => createPlaylist(user)}>
              <div className="content">Create Playlist</div>
            </Label>
          </div>
        )}
      </div>
      <div className="playlist">
        {sortedMusic.map((track: any, index: number) => {
          const { songs = [] } = userPlaylist || {};
          const isSongPresentInUserPlaylist = songs.some((songId: string) => songId === track.id);
          const addToPlaylistLocal = (e: any) => {
            e.stopPropagation();
            addToPlaylist(defaultPlaylistId, track.id);
          }
          const removeFromPlaylistLocal = (e: any) => {
            e.stopPropagation();
            removeFromPlaylist(defaultPlaylistId, track.id);
          }
          return (
            <div key={index} className="song-item" onClick={() => {updateCurrentTrack(track.id)}}>
              <Image src={PlayButton} size="mini" />
              <div className="song-title">
                <span className="content">{track.title}</span>
                {user && user.id && 
                  <div className="download-link">
                    {isSongPresentInUserPlaylist ? <Icon name="remove" color="red" onClick={removeFromPlaylistLocal}/> : <Icon name="add" onClick={addToPlaylistLocal} color="green"></Icon>}
                  </div>
                }
                {user && user.id && 
                  <div className="download-link">
                    <a onClick={(e: any) => { e.stopPropagation()}} href={track.link} download={`${track.title}.mp3`} rel="noreferrer" target="_blank"><Icon name="download" /></a>
                  </div>
                }
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Index;