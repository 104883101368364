import { Firestore } from '../../../lib/firebase';
import User from '../../models/User';
import { AuthTypeActions, AUTH_LOGIN, AUTH_LOGOUT, AUTH_UPDATE, USER_PLAYLIST_UPDATE } from './types';

export function login(user: any): any {
  return (dispatch: any) => {
    dispatch({type: AUTH_LOGIN, payload: user});
  }
}

export function updateUser(user: User): AuthTypeActions {
  return {
    type: AUTH_UPDATE,
    payload: user
  }
}

export function logout(): any {
  return (dispatch: any) => {
    dispatch({type: AUTH_LOGOUT});
  }
}

export const CREATE_PLAYLIST = (user: any) => {
  return (dispatch: any) => {
    Firestore.collection("playlists").add({
      userId: user.id,
      name: 'Default',
      songs: [],
    }).then((docRef) => {
      const playListId = docRef.id;
      Firestore.collection("users").doc(user.id).set(Object.assign({}, user, {playlists: [playListId]})).then((doc) => {
        const updatedUser = Object.assign({}, user, {
          playlists: [playListId]
        });
        dispatch({type: AUTH_UPDATE, payload: updatedUser});
      });
    });
  }
}

export const ADD_TO_PLAYLIST = (listId: string, songId: string) => {
  return (dispatch: any) => {
    Firestore.collection("playlists").doc(listId).get().then((doc) => {
      const data : any = doc.data();
      const { songs } = data;
      if (!songs.some((id: string) => id === songId)) {
        songs.push(songId);
      }
      const playlist = Object.assign({}, data, { songs });
      Firestore.collection("playlists").doc(listId).set(playlist);
      // dispatch({type: USER_PLAYLIST_UPDATE, payload: data});
    })
  }
}

export const REMOVE_FROM_PLAYLIST = (listId: string, songId: string) => {
  return (dispatch: any) => {
    Firestore.collection("playlists").doc(listId).get().then((doc) => {
      const data : any = doc.data();
      const { songs } = data;
      const targetSongIndex = songs.findIndex((id: string) => id === songId);
      if (targetSongIndex !== -1) {
        songs.splice(targetSongIndex, 1);
        const playlist = Object.assign({}, data, { songs });
        Firestore.collection("playlists").doc(listId).set(playlist);
      }
      // dispatch({type: USER_PLAYLIST_UPDATE, payload: data});
    })
  }
}

export const FETCH_USER_PLAYLIST = (id: string) => {
  return (dispatch: any) => {
    Firestore.collection("playlists").doc(id).onSnapshot((doc) => {
      const data = doc.data();
      dispatch({type: USER_PLAYLIST_UPDATE, payload: data});
    })
  }
}

export const FETCH_REMOTE_PLAYLIST = async (id: string) => {
  try {
    const querySnapshot = await Firestore.collection("playlists").doc(id).get();
    return querySnapshot.data();
  } catch (error) {
    console.log(error);
  }
}

export const SEND_OTP_TO_DB = async (email: string, otp: number) => {
  try {
    const querySnapshot = await Firestore.collection("users").where('email', '==', email).get();
    if (querySnapshot.size > 0) {
      const users : any[] = [];
      querySnapshot.forEach(doc => {
        users.push(Object.assign({}, {
          id: doc.id
        }, doc.data()));
      });
      const user = users.length > 0 && users[0];
      await Firestore.collection("users").doc(user.id).set(Object.assign({}, user, {otp_status: "SENT", otp }));
    } else {
      await Firestore.collection("users").add({ email, otp, otp_status: "SENT" });
    }
    return Object.assign({
      isError: false,
      data: true,
    });
  } catch (error) {
    return Object.assign({
      isError: true,
      data: false,
      message: 'Error in sending OTP',
    });
  }
}

export const VERIFY_OTP = async (email: string, otp: string) => {
  try {
    const querySnapshot = await Firestore.collection("users").where('email', '==', email).get();
    if (querySnapshot.size > 0) {
      const users : any[] = [];
      querySnapshot.forEach(doc => {
        users.push(Object.assign({}, {
          id: doc.id
        }, doc.data()));
      });
      const user = users.length > 0 && users[0];
      console.log(user);
      if (user && user.otp === parseInt(otp) && user.otp_status === 'SENT') {
        await Firestore.collection("users").doc(user.id).set(Object.assign({}, user, {otp_status: "VERIFIED"}));
        return Object.assign({
          isError: false,
          data: user,
          message: 'OTP verified successfully',
        });
      }
    }
    return Object.assign({
      isError: true,
      data: false,
      message: 'OTP verification failed',
    });
  } catch (error) {
    return Object.assign({
      isError: true,
      data: false,
      message: 'OTP verification failed',
    });
  }
}