import { createStore, applyMiddleware, compose } from 'redux';
import { rootReducer, RootState } from './rootReducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

// Redux Persist config
const config = {
  key: 'root',
  storage,
  blacklist: [],
};

const reducer = persistReducer<RootState>(config, rootReducer);
const middleware = [thunk];

const configureStore = () => {
  const composeEnhancer = compose;
  const store = createStore(reducer, composeEnhancer(applyMiddleware(...middleware)));

  const persistor = persistStore(store, null, () => {
    store.getState();
  });

  return {
    persistor,
    store
  };
};

export default configureStore;