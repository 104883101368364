import axios from 'axios';

export const VALIDATE_EMAIL = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const EMAIL_OTP_GENERATOR = () => Math.floor(100000 + Math.random() * 900000);

export const SEND_OTP = async (email: string, otp: number) => {
  const emailData: Object = {
    service_id: "service_lejepgk",
    template_id: "template_i7rog4o",
    user_id: "user_e7lPHnPvYClnbfsN7nUyf",
    template_params: {
      user_email: email,
      otp,
    }
  };
  try {
    const data = await axios.post(`https://api.emailjs.com/api/v1.0/email/send`, emailData, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return Object.assign({}, {
      isError: false,
      data,
    })
  } catch (error) {
    console.log(error);
    return Object.assign({
      isError: true,
      data: false,
      message: 'Error in sending OTP',
    });
  }
}