import { MusicTypeActions, FETCH_MUSIC_SUCCESS, MusicState } from './types';

const defaultMusic: never[] = []
const initialState: MusicState = {
  music: defaultMusic
}

export function musicReducer(
  state = initialState,
  action: MusicTypeActions | any
): MusicState | any {
  switch (action.type) {
    case FETCH_MUSIC_SUCCESS:
      return { ...state, music: action.payload }
    default:
      return state
  }
}