import React, { useCallback, useEffect, useState } from 'react';
import { connect } from "react-redux";
import { FETCH_REMOTE_PLAYLIST } from '../../../web/store/auth/actions';
import { updateAppliedFilters } from '../../../web/store/filters/action';
import Player from '../../home/UI/player/musicPlayer/index';

const Index = (props: any) => {
  const { pathname } = window.location;
  const playlistId = pathname.split('/').pop() || '';
  
  if (!playlistId) {
    <div>
      No Playlist Found
    </div>
  }

  const [playlist, updatePlaylist] = useState([]);
  const fetchPlaylist = useCallback(async () => {
    const userPlaylist = await FETCH_REMOTE_PLAYLIST(playlistId);
    if (userPlaylist) {
      updatePlaylist(userPlaylist.songs);
    }
  }, [playlistId]);
  useEffect(() => {
    fetchPlaylist();
  }, [fetchPlaylist]);
  const { music } = props;
  const filteredMusic = playlist.map((id: string) => music.find((song: any) => song.id === id));
  return (
    <div style={{marginTop: '4rem'}}>
      <Player {...props} filteredMusic={filteredMusic} shared/>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    music: state.musicReducer.music,
    filters: state.filterReducer.filters,
    appliedFilters: state.filterReducer.appliedFilters,
    sync: state.syncReducer.sync,
    user: state.authReducer.user,
    userPlaylist: state.authReducer.userPlaylist,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateFilters: (key: string, id: string) => dispatch(updateAppliedFilters(key, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);