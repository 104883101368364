import React from 'react';
import { Grid, Image } from 'semantic-ui-react';
import MoneyBag from '../../../assets/img/subscribe/moneyBag.svg';
import Music from '../../../assets/img/subscribe/music.svg';
import Policy from '../../../assets/img/subscribe/policy.svg';

const Index = () => {
  return (
    <div className="subscribe-container">
      <div className="title-container">
        <div className="title unica-one-font text-center">Subscription</div>
      </div>
      <div className="content abel-font">
        World Music Hub is a one of its kind all-inclusive production music library providing authentic world music for TV shows, films, trailers and commercials. We have the deepest and broadest world music collection with over 120 catalogs representing 104 countries and genres. More than 5000 high-quality tracks are curated, recorded and produced by the best musicians in the world using local, folk instruments. All master and publishing rights are reserved by us and we assure hassle-free licensing and faster clearance.
      </div>
      <div className="content abel-font">
        We provide a full range of services including easy search and download, custom writing and tailor-made subscriptions. The subscription options we offer are customized for television networks, music supervisors and film production houses depending on distinct requirements and finances.
      </div>
      <div>
        <Grid stackable centered>
          <Grid.Row>
            <Grid.Column width={4}>
              <div className="text-center">
                <div className="subscribe-item-container">
                  <div className="item-image-container">
                    <Image src={MoneyBag} className="item-image"/>
                  </div>
                  <hr className="horizontal-rule"/>
                  <div className="item-content">
                    Highly cost-effective
                    <br/>
                    access to the library
                  </div>
                </div>
              </div>
            </Grid.Column>
            <Grid.Column width={4}>
              <div className="text-center">
                <div className="subscribe-item-container">
                  <div className="item-image-container">
                    <Image src={Music} className="item-image"/>
                  </div>
                  <hr className="horizontal-rule"/>
                  <div className="item-content">
                    Custom written
                    <br/>
                    music services
                  </div>
                </div>
              </div>
            </Grid.Column>
            <Grid.Column width={4}>
              <div className="text-center">
                <div className="subscribe-item-container">
                  <div className="item-image-container">
                    <Image src={Policy} className="item-image"/>
                  </div>
                  <hr className="horizontal-rule"/>
                  <div className="item-content">
                    No clearance
                    <br/>
                    required
                  </div>
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <div>
        <Grid centered stackable>
          <Grid.Row>
            <Grid.Column width={10}>
              <div className="subscribe-app-container">
                <div className="title text-center">To subcribe, please reach out to us on</div>
                <div className="email-container">
                  <a href="mailto:subscribe@wmh.ai" className="emailTo unica-one-font">subscribe@wmh.ai</a>
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  )
}

export default Index;