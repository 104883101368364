import { Firestore } from '../../../lib/firebase';

export function fetchMusic(): any {
  return (dispatch: any) => {
    Firestore.collection("music").onSnapshot((snapshot) => {
      const { docs } = snapshot;
      const data = docs.map(doc => doc.data());
      dispatch({type: 'FETCH_MUSIC_SUCCESS', payload: data});
    });
  }
}