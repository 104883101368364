import React from 'react';
import { connect } from "react-redux";
import ExploreTypes from './types';
import ExploreRegions from './regions';
import { applySingleFilterType } from '../../../web/store/filters/action';

const Index = (props: any) => {
  return (
    <div className="explore-container">
      <div className="title-container">
        <div className="title unica-one-font text-center">Explore</div>
      </div>
      <ExploreTypes {...props}/>
      <ExploreRegions {...props}/>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    music: state.musicReducer.music,
    filters: state.filterReducer.filters,
    appliedFilters: state.filterReducer.appliedFilters,
    sync: state.syncReducer.sync
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateFilters: (key: string, filters: Array<string>) => dispatch(applySingleFilterType(key, filters)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);