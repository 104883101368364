import Song from "../../models/Song";

/**
 * Actions
 */

export const FETCH_MUSIC_SUCCESS = 'FETCH_MUSIC_SUCCESS';

export interface MusicState {
  music: Array<Song>
}

export interface MusicFetchAction {
  type: typeof FETCH_MUSIC_SUCCESS,
  payload: Array<Song>
}

export type MusicTypeActions = MusicFetchAction;